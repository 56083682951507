<template>
  <div class="main_profile_wrapper">
    <!-- Start Patterns -->
    <UserPattern :userScore="userScore"></UserPattern>
    <!-- End Patterns -->

    <!-- Table And Data -->
    <div class="main_table">
      <TableHeaderSection
        @toggleModal="ToggleTaskModal('add')"
        :title="`${localization('At Work Tasks')}`"
        :text="`${localization('Manage and edit your tasks or add new ones.')}`"
        :buttonText="`${localization('Add new task')}`"
        :total="atWorkTasksCount"
      ></TableHeaderSection>
      <!-- Start Main Table Here  padding -->
      <div class="header_container just_custom_table_tasks">
        <CustomTable
          class=""
          :count="atWorkTasksCount"
          :DataItems="atWorkTasks"
          @Refresh="refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'Tasks'"
          hidden_export_import
          admin_permission="admin_tasks"
          edit_permission="add_tasks"
          @selection="selections"
          :empty-state-title="`${localization('No tasks founded')}`"
          :empty-state-text="`${localization('Your search did not match any task. Please Check your inputs and try again.')}`"
          :empty-state-image="require('@/assets/new-theme/empty-state.png')"
          :show-default-empty-actions="false"
          @reset-filters="filter = {}"
          @toggleModal="ToggleTaskModal('add')"
          addNewText="Add new task"
          viewName="Tasks"
        >
          <!-- search & filter fields  -->
          <template #filter>
            <el-select
              v-model="filter.topic_id"
              clearable
              placeholder="Topic - All"
              class="select_filter_custom"
            >
              <el-option
                v-for="(option, index) in atWorkTasksTopics"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </template>

          <!-- Tasks Table  -->
          <template #columns>
            <!-- Select Users -->
            <el-table-column type="selection" width="80"></el-table-column>

            <!-- Task Name  -->
            <el-table-column
              prop="name"
              sortable
              :label="`${localization('Name')}`"
            ></el-table-column>
            <!-- Task Topic -->
            <el-table-column
              prop="topic_name"
              sortable
              :label="`${localization('Topic')}`"
              class="d-none d-md-block"
            ></el-table-column>

            <!-- //? Task Actions  -->
            <el-table-column
              prop=""
              :label="`${localization('Selected Strategies')}`"
              min-width="100"
              width="auto"
            >
              <template slot-scope="scope">
                <div class="main_custom_parts_pattern">
                  <div class="part_pattern s">
                    <span>S</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count.Sequence
                        ? scope.row.selected_strategies_count.Sequence
                        : 0
                    }}</span>
                  </div>
                  <!-- .selected_strategies_count?.Sequence -->
                  <div class="part_pattern p">
                    <span>P</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count.Precision
                        ? scope.row.selected_strategies_count.Precision
                        : 0
                    }}</span>
                  </div>
                  <div class="part_pattern t">
                    <span>T</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count["Technical Reasoning"]
                        ? scope.row.selected_strategies_count[
                            "Technical Reasoning"
                          ]
                        : 0
                    }}</span>
                  </div>
                  <div class="part_pattern c">
                    <span>C</span>
                    <span>-</span>
                    <span>{{
                      scope.row.selected_strategies_count.Confluence
                        ? scope.row.selected_strategies_count.Confluence
                        : 0
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <!-- //? Task Actions  -->
            <el-table-column prop="" label="">
              <template slot-scope="scope">
                <div class="td_actions flex justify-content-end">
                  <!-- show task button  -->
                  <router-link
                    v-if="hasPermission('show_tasks')"
                    :to="`/new-theme/at-work/${scope.row.id}`"
                    v-tooltip.top-center="
                      `${localization('Show Task')}`
                    "
                    class="btn btn-info btn-link btn-xs"
                  >
                    <img
                      src="@/assets/new-theme/eye-inactive.png"
                      alt="eye-inactive"
                    />
                  </router-link>
                  <!-- Edit Task Button  -->
                  <a
                    v-if="hasPermission('edit_tasks')"
                    v-tooltip.top-center="
                      `${localization('Edit Task')}`
                    "
                    class="btn btn-warning btn-simple btn-link"
                    @click="ToggleTaskModalForEdit('edit', scope.row)"
                  >
                    <img
                      src="@/assets/new-theme/edit-05-inactive.png"
                      alt="edit-05-inactive"
                    />
                  </a>
                  <!-- <span class="btn btn-warning btn-simple btn-link">
                    <img
                      src="@/assets/new-theme/printer-inactive.png"
                      alt="printer-inactive"
                    />
                  </span> -->
                </div>
              </template>
            </el-table-column>
          </template>
          <!-- Empty State Actions -->
          <template #empty-actions>
            <button class="empty_state_button" @click="resetFilters">
              Clear Search
            </button>

            <AppButton
              @click="ToggleTaskModal('add')"
              text="Add new task"
            ></AppButton>
          </template>
        </CustomTable>
        <DeleteTasks
          v-if="selected.length > 0"
          :text="`${selected.length} tasks selected`"
          @close="resetSelections"
          @print="printTasks"
          @delete="deleteTasks"
        ></DeleteTasks>
      </div>
      <!-- End Main Table Here -->
    </div>
    <!-- End Table And Data -->

    <!-- Start Add Task Modal -->

    <!-- Task Modal -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      @close="resetForm"
      :close-on-click-modal="false"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/file.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
          <h3 class="title">{{ type == "add" ? localization("Add a new study-related task") : localization("Edit your study-related task") }} </h3>
        <p class="description">
          {{ localization('Add your own task and discover what patterns are needed to successfully complete it.') }}
        </p>
      </div>

      <!-- Task Form -->
      <el-form :model="task" :rules="rules" ref="taskForm" class="task-form">
        <div class="flex justify-content-between" style="gap: 10px">
          <div class="flex-grow-1">
            <!-- Task Name -->
            <el-form-item prop="name">
              <AppInputLabel :text="`${localization('Name your task')}`" :star="true"></AppInputLabel>
              <el-input
                class="new_theme_custom_input"
                v-model="task.name"
                :placeholder="`${localization('Give your task a name')}`"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex-grow-1">
            <!-- Task Topic -->
            <el-form-item prop="topic_id">
              <AppInputLabel :text="`${localization('Task category')}`" :star="true"></AppInputLabel>
              <el-select
                v-model="task.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in atWorkTasksTopics"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>

        <!-- Task Description -->
        <el-form-item prop="description">
          <AppInputLabel :text="`${localization('Task description')}`" :star="true"></AppInputLabel>
          <el-input
            class="new_theme_custom_input input_with_custom_text_area"
            type="textarea"
            v-model="task.description"
            :placeholder="`${localization('Add task description')}`"
            clearable
          />
        </el-form-item>
      </el-form>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-4">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          {{ localization('Cancel') }}
        </button>
        <button @click="submitForm" class="lci_btn save  lci_btn_modal_cancel">
          {{ localization('Confirm') }}
        </button>
      </div>
    </el-dialog>
    <!-- End Add Task Modal -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "WorkView",

  data() {
    return {
      showAddTask: false,
      dialogVisible: false,
      taskTypes: ["Study-related task", "Work-related task"],
      task: {
        name: "",
        topic_id: "",
        description: ""
      },
      fileList: [],
      rules: {
        name: [
          {
            required: true,
            message: "Please enter the task name",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please enter the task topic",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please enter a task description",
            trigger: "blur"
          }
        ]
      },
      filter: {},
      selected: [],
      loading: false,
      task_id: null,
      type: ""
    };
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userData() {
      return this.$store.state.user.user;
    },
    image() {
      if (this.userData.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.userData.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    },
    atWorkTasks() {
      return this.$store.getters["user/getAtWorkTasks"];
    },
    atWorkTasksCount() {
      return this.$store.getters["user/getAtWorkTasksCount"];
    },
    atWorkTasksTopics() {
      return this.$store.getters["user/getAtWorkTasksTopics"];
    }
  },
  methods: {
    resetFilters(){
      this.filter = {};
      this.$router.go();
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val;
    },
    // Get User Data And Set User Score
    // Use First:35 - 25
    // As Needed:24 - 18
    // Avoid:17 - 7
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        // this.selected = this.userData.selected_attributes;
        if(this.userData.sequence){
        }else {
          this.$router.push( '/new-theme/instructions' )
        }
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    ToggleTaskModal(type) {
      this.showAddTask = !this.showAddTask;
      this.dialogVisible = !this.dialogVisible;
      this.type = type;
    },
    stripHtml(html) {
      if (!html) return "";
      return html.replace(/<[^>]*>/g, ""); // Remove all HTML tags
    },
    ToggleTaskModalForEdit(type, taskData) {
      this.$store.dispatch("user/showAtWorkTask", { id: taskData.id }).then(() => {
        this.type = type;
        this.task_id = taskData.id;
        this.task.name = taskData.name;
        this.task.description = this.stripHtml(taskData.description);
        this.task.topic_id = taskData.topic_id;
        this.dialogVisible = !this.dialogVisible;
      })
    },
    refresh(query) {
      // handlerWorkTasks
      this.$store
        .dispatch("user/handlerWorkTasks", {
          id: this.mainUserId,
          query: query
        })
        .then(() => {
          this.$store.dispatch("user/handlerWorkTopics").then((res) => {
            console.log('--------------------------------------------------------- ', res);
          });
        });
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
      // this.selectedInst.users = Array.from(selections, el => el.id);
    },
    resetSelections() {
      this.selected = [];
      this.$refs.customTable.clearSelection();
    },
    // Start Extract Photo
    handleUploadSuccess(file, fileList) {
      this.fileList = fileList;
      this.handle_upload_file_img(file);
    },

    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("File size cannot exceed 5MB.");
      }
      return isLt5M;
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    removeFile(file) {
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    handle_upload_file_img(file) {
      this.loading = true;
      this.description_photo = file.raw;
      let payload = new FormData();
      payload.append("photo", file.raw);
      this.$store
        .dispatch("user/get_ocr_photo_description", payload)
        .then(response => {
          this.task.description = response.photo_description;
          this.loading = false;
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Please double check the extracted text!")}`,
            type: "success",
            confirmButtonText: `${this.localization("Ok")}`,
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
      this.fileList = [];
      this.loading = false;
    },

    // End Extract Photo
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.$store
              .dispatch("user/handleAddWorkTask", {
                query: this.task
              })
              .then(response => {
                this.dialogVisible = false;

                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.refresh({});
                this.$router.push(`/new-theme/at-work/${response.task.id}`);
              })
              .catch(_ => {
                this.dialogVisible = false;
              });
          } else {
            this.$store
              .dispatch("user/handleEditWorkTask", {
                query: this.task,
                id: this.task_id
              })
              .then(_ => {
                this.dialogVisible = false;

                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });

                this.refresh({});
                this.$router.push(`/new-theme/at-work/${this.task_id}`);
              })
              .catch(_ => {
                this.dialogVisible = false;
              });
          }

          // Implement strategy generation logic

        } else {
          // this.$message.success("Form is valid. Strategies will be generated.");
          // this.$message.error("Please fill out all required fields.");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.taskForm.resetFields();
      this.fileList = [];
      this.dialogVisible = false;
      // this.$v.$reset();
    },
    printTasks() {
      let payload = {
        tasks: this.selected
      };
      this.$store.dispatch("tasks/handlePrintSchoolTasks", payload).then(() => {
        window.print();
        this.refresh();
      });
    },
    deleteTasks() {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          let payload = {
            tasks: this.selected
          };
          this.$store.dispatch("user/handleDeleteWorkTask", payload).then(_ => {
            this.refresh(null);
            Swal.fire({
              title: "",
              icon: "success",
              type: "success",
              text: `${this.localization("Deleted")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              confirmButtonClass: "btn btn-success ",
              buttonsStyling: false
            });
          });
        }
      });
    }
  },
  mounted() {
    this.getMainUserData();
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
.lci_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  .lci_btn_modal_cancel {
    background-color: #fff;
    color: #667085;
    border-radius: 8px;
    padding: 12px 20px;
    border: 1px solid #eaecf0;
    width: calc(97% / 2);
    &.save {
      background-color: #7f56d9;
      color: #fff;
    }
  }
}
.main_custom_parts_pattern {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  .part_pattern {
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 16px;
    height: 25px;
    padding: 10px 13px;
    width: 55px;
    font-weight: 500;
    &.s {
      color: #39c;
      border-color: #39c;
    }
    &.p {
      color: #3c3;
      border-color: #3c3;
    }
    &.t {
      color: #f93;
      border-color: #f93;
    }
    &.c {
      color: #c36;
      border-color: #c36;
    }
  }
}
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;
}
.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  &.white_text {
    color: #fff;
  }
}
.padding {
  padding: 14px;
}
.main_table {
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #eaecf0;

  .head_table {
    border-bottom: 1px solid #eaecf0;
  }
}
</style>

<style scoped>
.task-type {
  margin-bottom: 20px;
}
.task-form {
  margin-bottom: 20px;
}
.file-upload {
  margin-bottom: 20px;
  text-align: center;
}

.el-upload {
  border: 2px dashed #d9d9d9;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}
::v-deep .input_with_custom_text_area .el-textarea__inner {
  word-break: break-word !important; /* Ensures long words break */
  overflow-wrap: break-word !important; /* Ensures text wraps properly */
  white-space: pre-wrap !important; /* Preserves line breaks */
}
</style>
